<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="w-full h-fit max-w-screen-2xl relative">
    <div class="flex flex-row justify-center items-start mb-4">
      <div
        class="flex flex-row sm:px-10 lg:px-0 items-center gap-1 flex-wrap w-full md:w-11/12"
      >
        <!-- Text filter -->
        <div class="w-full flex flex-wrap lg:flex-nowrap items-center gap-4">
          <TextInput
            v-model="filter"
            class="w-full"
            placeholder="Search plants ..."
          >
            <template #icon>
              <IconPack type="MagnifyingGlass" class="w-5 h-5 text-gray-500" />
            </template>
          </TextInput>
          <AppSwitch
            v-model="enabledOnly"
            class="whitespace-nowrap my-auto"
            right-label="Enabled Only"
          />
          <AppSwitch
            v-model="onlineOnly"
            class="whitespace-nowrap my-auto"
            right-label="Online Only"
          />
        </div>

        <SearchableDropdown
          searchable
          multiple
          ref="area"
          v-model="areaFilter"
          :options="computedAreaOptions"
          placeholder="Area"
        >
          <template #iconLeft>
            <IconPack type="ChevronDown" class="w-5 h-5 text-gray-500" />
          </template>
        </SearchableDropdown>

        <SearchableDropdown
          ref="systemType"
          class="bg-blue-400 bg-opacity-0"
          v-model="systemType"
          :options="[
            { name: 'Solarman', color: 'blue-400' },
            { name: 'Sunsynk', color: 'yellow-600' }
          ]"
          placeholder="System Type"
        >
          <template #iconLeft>
            <IconPack type="ChevronDown" class="w-5 h-5 text-gray-500" />
          </template>
        </SearchableDropdown>

        <SearchableDropdown
          ref="reloadStatus"
          v-model="reloadStatus"
          :options="[
            { name: 'Invited', color: 'secondary-400' },
            { name: 'Ready for Invite', color: 'emerald-500' },
            { name: 'Not Ready for Invite', color: 'gray-400' }
          ]"
          placeholder="Reload Status"
        >
          <template #iconLeft>
            <IconPack type="ChevronDown" class="w-5 h-5 text-gray-500" />
          </template>
        </SearchableDropdown>

        <SearchableDropdown
          ref="plantStatus"
          v-model="plantStatus"
          :options="[
            { name: 'Charging', color: 'blue-500' },
            { name: 'Optimise', color: 'green-500' },
            { name: 'Loadshedding', color: 'secondary-500' }
          ]"
          placeholder="Plant Status"
        >
          <template #iconLeft>
            <IconPack type="ChevronDown" class="w-5 h-5 text-gray-500" />
          </template>
        </SearchableDropdown>

        <SearchableDropdown
          ref="appStatus"
          class="bg-emerald-500 bg-opacity-0"
          v-model="appStatus"
          :options="[
            { name: 'Has app', color: 'emerald-500' },
            { name: 'App not set up', color: 'gray-400' }
          ]"
          placeholder="Connect App"
        >
          <template #iconLeft>
            <IconPack type="ChevronDown" class="w-5 h-5 text-gray-500" />
          </template>
        </SearchableDropdown>

        <SearchableDropdown
          ref="subscriptionType"
          v-model="subscriptionTypeFilter"
          :options="[
            { name: 'Reload Only', color: 'secondary-400' },
            { name: 'Reload Maintenance', color: 'primary-50' },
            { name: 'Reload Maintenance Annual', color: 'emerald-500' }
          ]"
          placeholder="Subscription Type"
        >
          <template #iconLeft>
            <IconPack type="ChevronDown" class="w-5 h-5 text-gray-500" />
          </template>
        </SearchableDropdown>

        <SearchableDropdown
          ref="subscriptionStatus"
          v-model="subscriptionStatus"
          :options="[
            { name: 'Active', color: 'emerald-500' },
            { name: 'Pending', color: 'secondary-400' },
            { name: 'Cancelled', color: 'red-400' },
            { name: 'Payment Failed', color: 'red-500' },
            { name: 'No Subscription', color: 'gray-400' }
          ]"
          placeholder="Subscription Status"
        >
          <template #iconLeft>
            <IconPack type="ChevronDown" class="w-5 h-5 text-gray-500" />
          </template>
        </SearchableDropdown>

        <!-- Month filter -->
        <SearchableDropdown
          ref="month"
          v-model="inspectionDue"
          :options="months"
          placeholder="Inspection Due"
        >
          <template #iconLeft>
            <IconPack type="ChevronDown" class="w-5 h-5 text-gray-500" />
          </template>
        </SearchableDropdown>

        <div class="flex items-center">
          <SearchableDropdown
            ref="sortBy"
            v-model="sortBy"
            :options="[
              'Battery',
              'System Size',
              'Created At',
              'Next Inspection'
            ]"
            placeholder="Sort"
          >
            <template #iconLeft>
              <IconPack type="ChevronDown" class="w-5 h-5 text-gray-500" />
            </template>
          </SearchableDropdown>
          <transition name="scale-quick" mode="out-in" appear>
            <AppSwitch
              v-if="sortBy && sortBy.length > 0"
              v-model="sortDirection"
              :right-label="sortDirection ? 'Ascending' : 'Descending'"
              label="Reload"
            />
          </transition>
        </div>
      </div>

      <div
        class="ml-auto flex h-full p-2 flex-col-reverse justify-end gap-5 items-center"
      >
        <div
          @click="resetFilters"
          v-if="shouldApplyFilters"
          class="flex gap-1 items-center rounded bg-white p-2 border border-gray-300 shadow-sm select-none text-gray-500 hover:bg-gray-100 transition-all duration-300 cursor-pointer"
        >
          <IconPack type="XMark" class="w-5 h-5 text-gray-500" />
          <p>Reset</p>
        </div>

        <AppButton
          size="xs"
          type="primary"
          :disabled="!filteredPlants?.length"
          v-tippy="{ content: 'Export filtered plants as CSV' }"
          @click="exportPlants"
          :pulse="exportingData"
        >
          <template #icon>
            <IconPack type="ArrowDownOnSquare" class="w-5 h-5 text-white" />
          </template>
          Export
        </AppButton>

        <div
          class="flex gap-1 my-auto items-center rounded bg-white p-2 border border-gray-300 shadow-sm text-gray-500"
        >
          <p class="text-gray-500">Total:</p>
          <transition name="scale-quick" mode="out-in" appear>
            <p v-if="!fetching" class="text-primary-500 font-bold">
              {{ totalPlants }}
            </p>
            <div
              v-else
              class="w-6 h-4 text-transparent rounded bg-gray-200 animate-pulse"
            >
              {{ totalPlants }}
            </div>
          </transition>
        </div>
      </div>
    </div>

    <div class="w-full flex gap-4 items-center mb-5">
      <AppSwitch v-model="bulkSelect" right-label="Bulk Select" />

      <!-- <transition-group name="slide-up" mode="out-in" appear> -->
      <div v-if="bulkSelect" class="flex items-center gap-1">
        <p class="font-bold text-sm">{{ selectedPlants.length }} selected</p>
        <IconPack
          v-if="selectedPlants.length > 0"
          type="XMark"
          class="w-4 h-4 stroke-2 text-primary-500 cursor-pointer"
          @click="selectedPlants = []"
        />
      </div>
      <div
        @click="selectAll"
        v-if="bulkSelect"
        class="flex gap-1 items-center rounded bg-white px-2 py-1 border border-gray-300 shadow-sm select-none text-gray-500 hover:bg-gray-100 transition-all duration-300 cursor-pointer"
      >
        <p>Select all</p>
      </div>
      <div v-if="bulkSelect" class="ml-auto">
        <AppButton
          size="xs"
          outline
          type="secondary"
          :disabled="!selectedPlants.length"
          class="group"
          @click="$refs.bulkSelectModal.show(selectedPlants)"
        >
          <template #icon>
            <IconPack
              type="MapPin"
              class="w-5 h-5 text-primary-500 -translate-y-0.5 group-hover:text-white"
            />
          </template>
          <p class="text-primary-500 group-hover:text-white">Change Area</p>
        </AppButton>
      </div>
      <!-- </transition-group> -->
    </div>
    <div class="w-full sm:px-10 lg:px-0 flex flex-col gap-5 lg:gap-2">
      <div class="w-full" v-for="(plant, i) in filteredPlants" :key="plant.id">
        <PlantCard
          clickable
          :selectable="bulkSelect"
          v-if="!loading"
          @showDetails="showPlantDetails"
          @select="selectPlant"
          @refresh="refresh"
          :selected="selectedPlants.some((p) => p.id === plant.id)"
          class="w-full hover:z-10"
          :plant="plant"
          :key="i"
        />
        <PlantLoader v-else class="w-full" :plant="plant" />
      </div>
    </div>

    <div class="w-full mt-4">
      <transition name="scale" mode="out-in" appear>
        <div v-if="fetching" class="w-full flex items-center justify-center">
          <AppSpinner class="w-8" />
        </div>
        <div v-else-if="totalPlants < 1" class="flex flex-col w-fit gap-4">
          <p>No jobs found matching the current filters.</p>
        </div>
      </transition>
      <AppPagination
        v-if="pageCount > 1 && !fetching"
        :pageCount="pageCount"
        :currentPage="parseInt(currentPage)"
        @previous="loadPreviousPage"
        @next="loadNextPage"
        @goto="gotoPage"
      />
    </div>
    <ConnectInviteModal />
    <ErrorModal ref="errorModal" />
    <PlantModal
      v-if="
        !loadingAreas && plants?.length > 0 && computedAreaOptions.length > 0
      "
      ref="plantModal"
      @refresh="refresh"
    />

    <BulkSelectModal v-if="bulkSelect" ref="bulkSelectModal" />
  </div>
</template>
<script>
import PlantCard from '../../components/Connect/PlantCard.vue';
import ConnectInviteModal from '../../components/Connect/InviteModal.vue';
import ErrorModal from '../../components/Modals/ErrorModal.vue';
import AppSwitch from '../../components/EW/AppSwitch.vue';
import AppPagination from '../../components/EW/AppPagination.vue';
import PlantLoader from '../../components/Connect/Skeletons/PlantLoader.vue';
import PlantModal from '../../components/Modals/PlantModal.vue';
import BulkSelectModal from '../../components/Modals/BulkSelectModal.vue';
import moment from 'moment';

export default {
  components: {
    PlantCard,
    ConnectInviteModal,
    ErrorModal,
    AppSwitch,
    AppPagination,
    PlantModal,
    PlantLoader,
    BulkSelectModal
  },
  data() {
    return {
      reloadEnabled: false,
      totalPlants: 0,
      selectedPlants: [],
      action: null,
      perPage: 50,
      pageCount: 0,
      type: null,
      plantState: null,
      filtered: false,
      loading: false,
      enabledFilter: false,
      onlineFilter: false,
      bulkSelect: false,
      loadingAreas: true,
      exportingData: false,
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    };
  },
  computed: {
    plants() {
      return this.$store.getters['Plants/plants'];
    },

    filteredPlants() {
      let filterList = this.plants;
      if (this.shouldApplyFilters) filterList = this.applyFilters(filterList);

      return filterList;
    },

    fetching() {
      return this.$store.getters['Plants/fetching'];
    },

    shouldApplyFilters() {
      return (
        this.filter ||
        this.reloadStatus ||
        this.plantStatus ||
        this.systemType ||
        this.appStatus ||
        this.subscriptionStatus ||
        this.sortBy ||
        this.areaFilter ||
        this.subscriptionTypeFilter ||
        this.inspectionDue
      );
    },
    currentPage: {
      get() {
        const value =
          parseInt(this.$route.query?.page?.toString()) ||
          localStorage.getItem('currentPlantPage') ||
          1;
        return value == 'NaN' ? 1 : value;
      },
      set(value) {
        if (value == 'NaN') value = 1;

        this.$router
          .replace({ query: { ...this.$route.query, page: value } })
          .then(() => {
            this.loadPlants();

            localStorage.setItem('currentPlantPage', value.toString());
          });
      }
    },
    filter: {
      get() {
        return this.$route.query?.query?.toString() || '';
      },
      set(value) {
        debounce(() => {
          const query =
            value !== ''
              ? { ...this.$route.query, page: 1, query: value }
              : { ...this.$route.query, query: undefined };
          this.$router.replace({ query: query });
        }, 300);
      }
    },
    inspectionDue: {
      get() {
        return this.$route.query?.inspectionDue
          ? this.$route.query.inspectionDue
          : '';
      },
      set(value) {
        const query =
          value.length > 0
            ? { ...this.$route.query, page: 1, inspectionDue: value }
            : { ...this.$route.query, inspectionDue: undefined };
        this.$router.replace({ query: query });
      }
    },
    systemType: {
      get() {
        this.setType(
          this.$route.query?.systemType ? this.$route.query.systemType : ''
        );
        return this.type === 'solarman-business'
          ? { name: 'Solarman', color: 'blue-400' }
          : this.type === 'sunsynk'
          ? { name: 'Sunsynk', color: 'yellow-600' }
          : '';
      },
      set(value) {
        const query =
          value.length > 0
            ? {
                ...this.$route.query,
                page: 1,
                systemType:
                  value[0].name == 'Solarman'
                    ? 'solarman-business'
                    : value[0].name == 'Sunsynk'
                    ? 'sunsynk'
                    : ''
              }
            : { ...this.$route.query, systemType: undefined };
        this.$router.replace({ query: query });
      }
    },
    areaFilter: {
      get() {
        return this.$route.query?.area ? this.$route.query.area : '';
      },
      set(value) {
        const query =
          value.length > 0
            ? { ...this.$route.query, page: 1, area: value }
            : { ...this.$route.query, area: undefined };
        this.$router.replace({ query: query });
      }
    },

    reloadStatus: {
      get() {
        const status = this.$route.query?.reloadStatus
          ? this.$route.query.reloadStatus
          : '';
        return status === 'Invited'
          ? { name: 'Invited', color: 'secondary-400' }
          : status === 'Ready for Invite'
          ? { name: 'Ready for Invite', color: 'emerald-500' }
          : status === 'Not Ready for Invite'
          ? { name: 'Not Ready for Invite', color: 'gray-400' }
          : '';
      },
      set(value) {
        const query =
          value.length > 0
            ? { ...this.$route.query, page: 1, reloadStatus: value[0].name }
            : { ...this.$route.query, reloadStatus: undefined };
        this.$router.replace({ query: query });
      }
    },

    plantStatus: {
      get() {
        this.setPlantState(
          this.$route.query?.plantStatus ? this.$route.query.plantStatus : ''
        );
        return this.plantState === 'Charging'
          ? { name: 'Charging', color: 'blue-500' }
          : this.plantState === 'Optimise'
          ? { name: 'Optimise', color: 'green-500' }
          : this.plantState === 'Loadshedding'
          ? { name: 'Loadshedding', color: 'secondary-500' }
          : '';
      },
      set(value) {
        const query =
          value.length > 0
            ? { ...this.$route.query, page: 1, plantStatus: value[0].name }
            : { ...this.$route.query, plantStatus: undefined };
        this.$router.replace({ query: query });
      }
    },

    appStatus: {
      get() {
        const status = this.$route.query?.appStatus
          ? this.$route.query.appStatus
          : '';
        return status === 'Has app'
          ? { name: 'Has app', color: 'emerald-500' }
          : status === 'App not set up'
          ? { name: 'App not set up', color: 'gray-400' }
          : '';
      },
      set(value) {
        const query =
          value.length > 0
            ? { ...this.$route.query, page: 1, appStatus: value[0].name }
            : { ...this.$route.query, appStatus: undefined };
        this.$router.replace({ query: query });
      }
    },

    subscriptionTypeFilter: {
      get() {
        const type = this.$route.query?.subscriptionType
          ? this.$route.query.subscriptionType
          : '';
        return type === 'Reload Maintenance'
          ? { name: 'Reload Maintenance', color: 'primary-50' }
          : type === 'Reload Only'
          ? { name: 'Reload Only', color: 'secondary-400' }
          : type === 'Reload Maintenance Annual'
          ? { name: 'Reload Maintenance Annual', color: 'emerald-500' }
          : '';
      },
      set(value) {
        const query =
          value.length > 0
            ? {
                ...this.$route.query,
                page: 1,
                subscriptionType: value[0].name
              }
            : { ...this.$route.query, subscriptionType: undefined };
        this.$router.replace({ query: query });
      }
    },

    subscriptionStatus: {
      get() {
        const status = this.$route.query?.subscriptionStatus
          ? this.$route.query.subscriptionStatus
          : '';
        return status === 'Active'
          ? { name: 'Active', color: 'emerald-500' }
          : status === 'Pending'
          ? { name: 'Pending', color: 'secondary-400' }
          : status === 'Cancelled'
          ? { name: 'Cancelled', color: 'red-400' }
          : status === 'Payment Failed'
          ? { name: 'Payment Failed', color: 'red-500' }
          : status === 'No Subscription'
          ? { name: 'No Subscription', color: 'gray-400' }
          : '';
      },
      set(value) {
        const query =
          value.length > 0
            ? {
                ...this.$route.query,
                page: 1,
                subscriptionStatus: value[0].name
              }
            : { ...this.$route.query, subscriptionStatus: undefined };
        this.$router.replace({ query: query });
      }
    },
    enabledOnly: {
      get() {
        return this.enabledFilter;
      },
      set(value) {
        this.enabledFilter = value;
        this.filtered = false;
        localStorage.setItem('enabledOnly', value);
        this.loading = true;
        this.loadPlants();
      }
    },
    onlineOnly: {
      get() {
        return this.onlineFilter;
      },
      set(value) {
        this.onlineFilter = value;
        this.filtered = false;
        localStorage.setItem('onlineOnly', value);
        this.loading = true;
        this.loadPlants();
      }
    },
    sortBy: {
      get() {
        const sort = this.$route.query?.sortBy ? this.$route.query.sortBy : '';
        return sort;
      },
      set(value) {
        const query =
          value.length > 0
            ? {
                ...this.$route.query,
                page: 1,
                sortBy: value,
                sortDirection: this.sortDirection ? 'asc' : 'desc'
              }
            : {
                ...this.$route.query,
                sortBy: undefined,
                sortDirection: undefined
              };
        this.onlineOnly = true;
        this.$router.replace({ query: query });
      }
    },
    sortDirection: {
      get() {
        const sort = this.$route.query?.sortDirection
          ? this.$route.query.sortDirection
          : '';
        return sort === 'asc';
      },
      set(value) {
        const query =
          value == true
            ? { ...this.$route.query, page: 1, sortDirection: 'asc' }
            : value == false
            ? { ...this.$route.query, sortDirection: 'desc' }
            : { ...this.$route.query, sortDirection: undefined };
        this.$router.replace({ query: query });
      }
    },
    areas() {
      return this.$store.getters['Plants/areas'];
    },
    computedAreaOptions() {
      // console.log('computedAreaOptions computed property called');
      return this.areas?.map((area) => area.attributes.name);
    }
  },
  async created() {
    // console.log('Plants.vue created hook called');
    await this.$store.dispatch('Plants/getAreas').catch((error) => {
      console.error('Error fetching areas data:', error);
    });
  },

  async mounted() {
    this.currentPage =
      parseInt(this.$route.query?.page?.toString()) ||
      localStorage.getItem('currentPlantPage')
        ? parseInt(localStorage.getItem('currentPlantPage'))
        : 1;
    this.enabledFilter = localStorage.getItem('enabledOnly') === 'true';
    this.onlineFilter = localStorage.getItem('onlineOnly') === 'true';
  },
  methods: {
    setType(value) {
      this.type = value;
    },

    selectAll() {
      if (this.selectedPlants.length === this.filteredPlants.length) {
        this.selectedPlants = [];
      } else {
        this.selectedPlants = this.filteredPlants;
      }
    },

    setPlantState(value) {
      this.plantState = value;
    },

    async loadPlants(download = false) {
      return new Promise((resolve, reject) => {
        let start = (this.currentPage - 1) * this.perPage;
        let limit = this.perPage;

        let query = this.getQuery();
        let sort = this.getSort();

        if (this.reloadStatus.name === 'Not Ready for Invite') {
          sort = {
            ...sort,
            reload_not_ready_reason: 'asc'
          };
        }

        if (download) {
          start = 0;
          limit = this.totalPlants;

          // if there are selected plants, fetch only those
          if (this.selectedPlants.length > 0) {
            query = {
              filters: {
                $or: []
              }
            };
            this.selectedPlants.forEach((plant) => {
              query.filters.$or.push({
                id: {
                  $eq: plant.id
                }
              });
            });
          }
        }

        debounce(async () => {
          await this.$store
            .dispatch('Plants/getPlants', {
              start: start,
              limit: limit,
              filters: query ? query : '',
              sort: sort ? sort : '',
              fetchOnly: download
            })
            .then((res) => {
              if (this.appStatus && this.appStatus.name === 'Has app') {
                this.$store.commit(
                  'Plants/SET_HAS_APP_COUNT',
                  res.data.meta.pagination.total
                );
              } else if (
                this.appStatus &&
                this.appStatus.name === 'App not set up'
              ) {
                if (this.$store.getters['Plants/hasAppCount'] > 0) {
                  res.data.meta.pagination.total =
                    res.data.meta.pagination.total -
                    this.$store.getters['Plants/hasAppCount'];
                }
              }
              this.totalPlants = res.data.meta.pagination.total;
              this.pageCount = Math.ceil(this.totalPlants / this.perPage);

              resolve(res.data.data);
            })
            .catch((err) => {
              this.$toast.error('Failed to load plants', {
                position: 'bottom',
                onClick: () => {
                  this.$refs.errorModal.show(err);
                }
              });
              reject(err);
            })
            .finally(() => {
              this.loading = false;
            });

          await this.$store.dispatch('Plants/getAreas').finally(() => {
            this.loadingAreas = false;
          });
        }, 200);
      });
    },

    applyFilters(filterList) {
      this.loadingAreas = true;
      if (this.filter) {
        filterList = filterList?.filter((plant) => {
          return plant.attributes.name
            .toLowerCase()
            .includes(this.filter.toLowerCase());
        });
      }

      if (this.inspectionDue) {
        filterList = filterList?.filter((plant) => {
          // console.log(
          //   'Inspection Due:',
          //   this.inspectionDue,
          //   moment(plant.attributes.next_inspection).format('MMMM')
          // );
          return (
            moment(plant.attributes.next_inspection).format('MMMM') ==
            this.inspectionDue
          );
        });
      }

      if (this.areaFilter) {
        filterList = filterList?.filter((plant) => {
          return (
            this.areaFilter.some((area) => {
              return plant.attributes.area?.data?.attributes.name == area;
            }) ||
            plant.attributes.area?.data?.attributes.name == this.areaFilter
          );
        });
      }

      if (this.systemType) {
        filterList = filterList?.filter((plant) => {
          return plant.attributes.integration.data?.attributes.uid == this.type;
        });
      }

      if (this.reloadStatus) {
        filterList = filterList?.filter((plant) => {
          const invites = plant.attributes.invites?.data;
          if (this.reloadStatus.name === 'Invited') {
            const status = !invites.some(
              (invite) => invite.attributes.status === 'completed'
            );
            if (!status) this.totalPlants--;
            return status;
          }
          if (this.reloadStatus.name === 'Ready for Invite') {
            return plant.attributes.ready_for_reload === true;
          }
          if (this.reloadStatus.name === 'Not Ready for Invite') {
            return (
              plant.attributes.ready_for_reload === false ||
              plant.attributes.ready_for_reload === null
            );
          }
        });
      }

      if (this.plantStatus) {
        filterList = filterList?.filter((plant) => {
          return (
            plant.attributes.status ==
            (this.plantState === 'Charging'
              ? 'charge'
              : this.plantState === 'Optimise'
              ? 'optimise'
              : this.plantState === 'Loadshedding'
              ? 'loadshedding'
              : null)
          );
        });
      }

      if (this.appStatus) {
        filterList = filterList?.filter((plant) => {
          const invites = plant.attributes.invites?.data;

          if (this.appStatus.name === 'Has app') {
            return invites.some(
              (invite) => invite.attributes.status === 'completed'
            );
          }
          if (this.appStatus.name === 'App not set up') {
            return invites.every(
              (invite) =>
                invite.attributes.status !== 'completed' ||
                invite.attributes.status === null
            );
          }
        });
      }

      if (this.subscriptionTypeFilter) {
        filterList = filterList?.filter((plant) => {
          return (
            plant.attributes.subscription?.data?.attributes.plan_code ==
            this.subscriptionTypeFilter.name.toUpperCase().replace(/ /g, '_')
          );
        });
      }

      if (this.subscriptionStatus) {
        filterList = filterList?.filter((plant) => {
          const subscription =
            plant.attributes.subscription?.data?.attributes.status;
          switch (this.subscriptionStatus.name) {
            case 'Active':
              return subscription === 'active';
            case 'Pending':
              return subscription === 'pending';
            case 'Cancelled':
              return subscription === 'cancelled';
            case 'Payment Failed':
              return subscription === 'payment_failed';
            case 'No Subscription':
              return subscription == undefined || subscription == null;
          }
        });
      }

      if (this.sortBy) {
        filterList = filterList?.sort((a, b) => {
          return this.sortDirection
            ? a.attributes[
                this.sortBy == 'Battery'
                  ? 'soc'
                  : this.sortBy == 'System Size'
                  ? 'system_capacity'
                  : this.sortBy == 'Created At'
                  ? 'created_at'
                  : this.sortBy == 'Next Inspection'
                  ? 'next_inspection'
                  : ''
              ] -
                b.attributes[
                  this.sortBy == 'Battery'
                    ? 'soc'
                    : this.sortBy == 'System Size'
                    ? 'system_capacity'
                    : this.sortBy == 'Created At'
                    ? 'created_at'
                    : this.sortBy == 'Next Inspection'
                    ? 'next_inspection'
                    : ''
                ]
            : b.attributes[
                this.sortBy == 'Battery'
                  ? 'soc'
                  : this.sortBy == 'System Size'
                  ? 'system_capacity'
                  : this.sortBy == 'Created At'
                  ? 'created_at'
                  : this.sortBy == 'Next Inspection'
                  ? 'next_inspection'
                  : ''
              ] -
                a.attributes[
                  this.sortBy == 'Battery'
                    ? 'soc'
                    : this.sortBy == 'System Size'
                    ? 'system_capacity'
                    : this.sortBy == 'Created At'
                    ? 'created_at'
                    : this.sortBy == 'Next Inspection'
                    ? 'next_inspection'
                    : ''
                ];
        });
      }

      if (!this.filtered) {
        this.loadPlants();
        this.filtered = true;
      }

      return filterList;
    },

    getSort() {
      if (this.sortBy) {
        return {
          [this.sortBy == 'Next Inspection'
            ? 'next_inspection'
            : this.sortBy == 'Battery'
            ? 'soc'
            : this.sortBy == 'System Size'
            ? 'system_capacity'
            : this.sortBy == 'Created At'
            ? 'created_at'
            : '']: this.sortDirection ? 'asc' : 'desc'
        };
      }
    },

    getQuery() {
      let query = {
        filters: {
          $and: []
        }
      };

      if (this.filter) {
        query.filters.$and.push({
          name: {
            $containsi: this.filter
          }
        });
      }

      if (this.areaFilter) {
        query.filters.$and.push({
          area: {
            name: {
              $containsi: this.areaFilter
            }
          }
        });
      }

      if (this.systemType) {
        query.filters.$and.push({
          integration: {
            uid: {
              $eq: this.type
            }
          }
        });
      }

      if (this.reloadStatus) {
        switch (this.reloadStatus.name) {
          case 'Invited':
            query.filters.$and.push({
              invites: {
                $and: [
                  {
                    status: {
                      $eq: 'pending'
                    }
                  },
                  {
                    status: {
                      $ne: 'completed'
                    }
                  }
                ]
              }
            });
            break;
          case 'Ready for Invite':
            query.filters.$and.push({
              $and: [
                {
                  ready_for_reload: {
                    $eq: true
                  }
                },
                {
                  invites: {
                    status: {
                      $null: true
                    }
                  }
                }
              ]
            });
            break;
          case 'Not Ready for Invite':
            query.filters.$and.push({
              $and: [
                {
                  $or: [
                    {
                      ready_for_reload: {
                        $eq: false
                      }
                    },
                    {
                      ready_for_reload: {
                        $null: true
                      }
                    }
                  ]
                },
                {
                  invites: {
                    status: {
                      $null: true
                    }
                  }
                }
              ]
            });
            break;

          default:
            break;
        }
      }

      if (this.plantStatus) {
        query.filters.$and.push({
          $and: [
            {
              status: {
                $eq:
                  this.plantState === 'Charging'
                    ? 'charge'
                    : this.plantState === 'Optimise'
                    ? 'optimise'
                    : this.plantState === 'Loadshedding'
                    ? 'loadshedding'
                    : null
              }
            },
            {
              reload_enabled: {
                $eq: true
              }
            }
          ]
        });
      }

      if (this.appStatus) {
        switch (this.appStatus.name) {
          case 'Has app':
            query.filters.$and.push({
              invites: {
                status: {
                  $eq: 'completed'
                }
              }
            });
            break;
          case 'App not set up':
            query.filters.$and.push({
              invites: {
                $or: [
                  {
                    $not: {
                      status: {
                        $eq: 'completed'
                      }
                    }
                  },
                  {
                    status: {
                      $null: true
                    }
                  }
                ]
              }
            });
            break;
        }
      }

      if (this.subscriptionTypeFilter) {
        query.filters.$and.push({
          subscription: {
            plan_code: {
              $eq: this.subscriptionTypeFilter.name
                .toUpperCase()
                .replace(/ /g, '_')
            }
          }
        });
      }

      if (this.subscriptionStatus) {
        switch (this.subscriptionStatus.name) {
          case 'Active':
            query.filters.$and.push({
              subscription: {
                status: {
                  $eq: 'active'
                }
              }
            });
            break;
          case 'Pending':
            query.filters.$and.push({
              subscription: {
                status: {
                  $eq: 'pending'
                }
              }
            });
            break;
          case 'Cancelled':
            query.filters.$and.push({
              subscription: {
                status: {
                  $eq: 'cancelled'
                }
              }
            });
            break;
          case 'Payment Failed':
            query.filters.$and.push({
              subscription: {
                status: {
                  $eq: 'payment_failed'
                }
              }
            });
            break;
          case 'No Subscription':
            query.filters.$and.push({
              subscription: {
                status: {
                  $null: true
                }
              }
            });
            break;
        }
      }

      // Inside getQuery() function
      if (this.inspectionDue) {
        const monthNames = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ];
        const inspectionMonthIndex =
          monthNames.indexOf(
            Array.isArray(this.inspectionDue)
              ? this.inspectionDue[0]
              : this.inspectionDue
          ) + 1;

        let currentYear = new Date().getFullYear();
        let overflowYear = false;
        let nextInspectionMonthIndex = inspectionMonthIndex + 1;

        if (nextInspectionMonthIndex > 12) {
          nextInspectionMonthIndex = 1;
          overflowYear = true;
        }

        query.filters.$and.push({
          $or: [
            {
              $and: [
                {
                  next_inspection: {
                    $gte:
                      currentYear +
                      '-' +
                      (inspectionMonthIndex < 10 ? '0' : '') +
                      inspectionMonthIndex
                  }
                },
                {
                  next_inspection: {
                    $lt:
                      (overflowYear ? currentYear + 1 : currentYear) +
                      '-' +
                      (nextInspectionMonthIndex < 10 ? '0' : '') +
                      nextInspectionMonthIndex
                  }
                }
              ]
            },
            {
              $and: [
                {
                  next_inspection: {
                    $gte:
                      currentYear +
                      1 +
                      '-' +
                      (inspectionMonthIndex < 10 ? '0' : '') +
                      inspectionMonthIndex
                  }
                },
                {
                  next_inspection: {
                    $lt:
                      (overflowYear ? currentYear + 2 : currentYear + 1) +
                      '-' +
                      (nextInspectionMonthIndex < 10 ? '0' : '') +
                      nextInspectionMonthIndex
                  }
                }
              ]
            }
          ]
        });
      }

      if (this.onlineOnly) {
        query.filters.$and.push({
          online: {
            $eq: true
          }
        });
      }

      if (this.enabledOnly) {
        query.filters.$and.push({
          reload_enabled: {
            $eq: true
          }
        });
      }

      return query;
    },

    selectPlant(plant) {
      console.log('selectPlant method called', plant);
      if (plant.selected) {
        this.selectedPlants.push(plant);
      } else {
        this.selectedPlants = this.selectedPlants.filter(
          (p) => p.id !== plant.id
        );
      }
    },

    showPlantDetails(plant) {
      // console.log('showPlantDetails method called');
      if (this.bulkSelect) {
        plant.selected = !plant.selected;
        this.selectPlant(plant);
        return;
      }

      this.$refs.plantModal?.show(plant, this.computedAreaOptions);
    },
    resetFilters() {
      this.filter = '';
      this.systemType = '';
      this.reloadStatus = '';
      this.plantStatus = '';
      this.appStatus = '';
      this.sortBy = '';
      this.sortDirection = null;
      this.subscriptionStatus = '';
      this.$router.replace({ query: { page: 1 } });
      this.filtered = false;
    },
    loadPreviousPage() {
      if (this.currentPage > 1) {
        this.loading = true;
        this.currentPage--;
      }
    },

    loadNextPage() {
      if (this.currentPage < this.pageCount) {
        this.loading = true;
        this.currentPage++;
      }
    },

    gotoPage(page) {
      if (page === '...') {
        return;
      }
      this.loading = true;
      this.currentPage = page;
    },

    onFilterChange(newValue) {
      this.filtered = false;
      if (newValue === '' && !this.shouldApplyFilters) {
        this.currentPage = 1;
      }
    },
    async exportPlants() {
      this.$toast.show(
        'Exporting data, this may take a while. Please wait...',
        {
          duration: 5000,
          position: 'bottom'
        }
      );
      this.exportingData = true;
      try {
        const data = await this.loadPlants(true);

        if (!data || data.length === 0) {
          this.$toast.error('No data to export.');
          return;
        }

        // console.log('data:', data);

        //download filteredPlants as csv
        const csvData = data.map((plant) => {
          return {
            'Plant Name': plant.attributes.name,
            Connection: plant.attributes.online ? 'online' : 'offline',
            'Current SOC': plant.attributes.soc,
            'System Size': plant.attributes.system_capacity,
            'System Type': plant.attributes.integration.data.attributes.uid,
            'Next Inspection': plant.attributes.next_inspection
              ? moment(plant.attributes.next_inspection).format('YYYY-MM-DD')
              : '',
            'Reload Enabled': plant.attributes.reload_enabled
              ? 'enabled'
              : 'disabled',
            'Reload Status': plant.attributes.ready_for_reload
              ? 'ready'
              : 'not ready',
            'Plant Status': plant.attributes.status || '',
            'App Status': plant.attributes.invites.data.some(
              (invite) => invite.attributes.status === 'completed'
            )
              ? 'has app'
              : 'not set up',
            'Subscription Status': plant.attributes.subscription.data
              ? plant.attributes.subscription.data.attributes.status
              : 'none',
            Notes: plant.attributes.notes ? plant.attributes.notes : ''
          };
        });

        const csvRows = [];
        const headers = Object.keys(csvData[0]);
        csvRows.push(headers.join(','));

        for (const row of csvData) {
          const values = headers.map((header) => {
            const escaped = ('' + row[header]).replace(/"/g, '\\"');
            return `"${escaped}"`;
          });
          csvRows.push(values.join(','));
        }

        const csvContent = csvRows.join('\n');

        const downloadLink = document.createElement('a');
        const blob = new Blob([csvContent], {
          type: 'text/csv;charset=utf-8;'
        });
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = 'plants.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        this.$toast.success('Data exported successfully.', {
          position: 'bottom'
        });
      } catch (error) {
        console.error('Error exporting data:', error);
        this.$toast.error('Failed to export data', {
          position: 'bottom'
        });
      } finally {
        this.exportingData = false;
      }
    },
    refresh(plant) {
      this.$store.dispatch('Plants/refreshPlant', plant.id).then(() => {
        this.$toast.show('Refresh might take a while, check back in a minute', {
          duration: 10000,
          position: 'top'
        });

        setTimeout(() => {
          this.loadPlants();
          this.$toast.success(`${plant?.attributes.name} refreshed`);
        }, 25000);
      });
    }
  },
  watch: {
    filter(newValue) {
      this.onFilterChange(newValue);
    },
    areaFilter(newValue) {
      this.onFilterChange(newValue);
    },
    systemType(newValue) {
      this.onFilterChange(newValue);
    },
    reloadStatus(newValue) {
      this.onFilterChange(newValue);
    },
    plantStatus(newValue) {
      this.onFilterChange(newValue);
    },
    appStatus(newValue) {
      this.onFilterChange(newValue);
    },
    sortBy(newValue) {
      this.onFilterChange(newValue);
    },
    sortDirection(newValue) {
      this.onFilterChange(newValue);
    },
    subscriptionStatus(newValue) {
      this.onFilterChange(newValue);
    },
    subscriptionTypeFilter(newValue) {
      this.onFilterChange(newValue);
    },
    bulkSelect(newValue) {
      if (!newValue) {
        this.selectedPlants = [];
      }
    },
    inspectionDue(newValue) {
      this.onFilterChange(newValue);
    }
  }
};

let timeout;
const debounce = (func, delay) => {
  clearTimeout(timeout);
  timeout = setTimeout(func, delay);
};
</script>
